import React from "react";
import './Body.css';

const Body = () => {
    return(
        <div className="body-div">
            <a href="https://itaxudata-sandbox.netlify.app">Sandbox</a>
            <h1>Lagt till 22 januari 2025</h1>
            <h1>Quamquam in hac divisione.</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quibusnam praeteritis? Non quam nostram quidem, inquit Pomponius iocans; </p>
            <h2>Vide, quantum, inquam, fallare, Torquate.</h2>
            <p>Si id dicis, vicimus. At hoc in eo M. Huius, Lyco, oratione locuples, rebus ipsis ielunior. Qui-vere falsone, quaerere mittimus-dicitur oculis se privasse; Venit ad extremum; Aliter enim nosmet ipsos nosse non possumus. Si longus, levis; </p>
            <p>Duo Reges: constructio interrete. Nescio quo modo praetervolavit oratio. Videamus animi partes, quarum est conspectus illustrior; </p>
            <h2>Vide, quantum, inquam, fallare, Torquate.</h2>
            <p>Si id dicis, vicimus. At hoc in eo M. Huius, Lyco, oratione locuples, rebus ipsis ielunior. Qui-vere falsone, quaerere mittimus-dicitur oculis se privasse; Venit ad extremum; Aliter enim nosmet ipsos nosse non possumus. Si longus, levis; </p>
            <p>Duo Reges: constructio interrete. Nescio quo modo praetervolavit oratio. Videamus animi partes, quarum est conspectus illustrior; </p>
            <h2>Vide, quantum, inquam, fallare, Torquate.</h2>
            <p>Si id dicis, vicimus. At hoc in eo M. Huius, Lyco, oratione locuples, rebus ipsis ielunior. Qui-vere falsone, quaerere mittimus-dicitur oculis se privasse; Venit ad extremum; Aliter enim nosmet ipsos nosse non possumus. Si longus, levis; </p>
            <p>Duo Reges: constructio interrete. Nescio quo modo praetervolavit oratio. Videamus animi partes, quarum est conspectus illustrior; </p>

            <h1>Quamquam in hac divisione.</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quibusnam praeteritis? Non quam nostram quidem, inquit Pomponius iocans; </p>
            <h2>Vide, quantum, inquam, fallare, Torquate.</h2>
            <p>Si id dicis, vicimus. At hoc in eo M. Huius, Lyco, oratione locuples, rebus ipsis ielunior. Qui-vere falsone, quaerere mittimus-dicitur oculis se privasse; Venit ad extremum; Aliter enim nosmet ipsos nosse non possumus. Si longus, levis; </p>
            <p>Duo Reges: constructio interrete. Nescio quo modo praetervolavit oratio. Videamus animi partes, quarum est conspectus illustrior; </p>
            <h2>Vide, quantum, inquam, fallare, Torquate.</h2>
            <p>Si id dicis, vicimus. At hoc in eo M. Huius, Lyco, oratione locuples, rebus ipsis ielunior. Qui-vere falsone, quaerere mittimus-dicitur oculis se privasse; Venit ad extremum; Aliter enim nosmet ipsos nosse non possumus. Si longus, levis; </p>
            <p>Duo Reges: constructio interrete. Nescio quo modo praetervolavit oratio. Videamus animi partes, quarum est conspectus illustrior; </p>
            <h2>Vide, quantum, inquam, fallare, Torquate.</h2>
            <p>Si id dicis, vicimus. At hoc in eo M. Huius, Lyco, oratione locuples, rebus ipsis ielunior. Qui-vere falsone, quaerere mittimus-dicitur oculis se privasse; Venit ad extremum; Aliter enim nosmet ipsos nosse non possumus. Si longus, levis; </p>
            <p>Duo Reges: constructio interrete. Nescio quo modo praetervolavit oratio. Videamus animi partes, quarum est conspectus illustrior; </p>
        </div>
    )
}

export default Body;
